@font-face {
  font-family: "MStiffHei HK";
  src: url(./fonts/MStiffHei-HK-Medium.ttf) format("truetype");
  font-weight: normal;
  unicode-range: U+4E00-9FFF;
}

@font-face {
  font-family: "MStiffHei HK";
  src: url(./fonts/MStiffHei-HK-Bold.ttf) format("truetype");
  font-weight: bold;
  unicode-range: U+4E00-9FFF;
}

@font-face {
  font-family: "Gotham Book";
  src: url(./fonts/Gotham-Book.ttf) format("truetype");
  font-weight: normal;
}
@font-face {
  font-family: "Gotham Book";
  src: url(./fonts/Gotham-Bold.otf) format("opentype");
  font-weight: bold;
}

@font-face {
  font-family: "MyriadPro";
  src: url(./fonts/MyriadPro-Regular.otf) format("opentype");
  font-weight: normal;
}

@font-face {
  font-family: "DIN";
  src: url(./fonts/DIN-Bold.otf) format("opentype");
  font-weight: bold;
}

@-webkit-keyframes scale-up-center {
  0% {
    -webkit-transform: scale(0.8);
    transform: scale(0.8);
  }
  50% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
  100% {
    -webkit-transform: scale(0.8);
    transform: scale(0.8);
  }
}
@keyframes scale-up-center {
  0% {
    -webkit-transform: scale(0.8);
    transform: scale(0.8);
  }
  50% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
  100% {
    -webkit-transform: scale(0.8);
    transform: scale(0.8);
  }
}

#calbee-join-now-btn {
  -webkit-animation: scale-up-center 1s cubic-bezier(0.39, 0.575, 0.565, 1) both;
  animation: scale-up-center 1s cubic-bezier(0.39, 0.575, 0.565, 1) both;
  animation-delay: 0.25s;
  animation-iteration-count: infinite;
}

body {
  font-family: "MStiffHei HK", "DIN", "Open Sans", "Microsoft JhengHei",
    "微軟正黑體", sans-serif;
}

h3 {
  font-family: "MStiffHei HK", "DIN", "Open Sans", "Microsoft JhengHei",
    "微軟正黑體", sans-serif;
  font-size: 22px;
  letter-spacing: 2px;
  font-weight: bold;
  color: #000;
}

h4 {
  font-family: "MStiffHei HK", "DIN", "Open Sans", "Microsoft JhengHei",
    "微軟正黑體", sans-serif;
  font-size: 18px;
  letter-spacing: 2px;
}

h5 {
  font-family: "MStiffHei HK", "DIN", "Open Sans", "Microsoft JhengHei",
    "微軟正黑體", sans-serif;
  font-size: 14px;
}

button {
  border: none;
  background-color: transparent;
}

button > img,
button > h3 {
  cursor: pointer;
}

a {
  text-decoration: none;
}

input {
  font-family: "MStiffHei HK", "MyriadPro", "Open Sans", "Microsoft JhengHei",
    "微軟正黑體", sans-serif;
  border: none;
  font-size: 18px;
  text-align: center;
  letter-spacing: 2px;
  background-color: transparent;
  outline: none;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  transition: background-color 5000s ease-in-out 0s;
}

table {
  font-family: "MStiffHei HK", "MyriadPro", "Open Sans", "Microsoft JhengHei",
    "微軟正黑體", sans-serif;
}

label {
  font-family: "MStiffHei HK";
  font-size: 14px;
  letter-spacing: 2px;
}

.MuiPaper-root.MuiPaper-elevation.MuiPaper-elevation16.MuiDrawer-paper.MuiDrawer-paperAnchorLeft.css-4t3x6l-MuiPaper-root-MuiDrawer-paper {
  display: inline-block;
  position: relative;
}

.makeStyles-qr-15 div {
  box-shadow: rgb(255 255 255 / 50%) 0px 0px 0px 5px inset !important;
}

.makeStyles-qr-15 section {
  border-radius: 25px;
}

.slick-dots li.slick-active button:before {
  opacity: 1 !important;
  color: #ec1c24 !important;
}

.slick-dots li {
  width: 16px !important;
  height: 28px !important;
  margin: 0 !important;
}

.slick-dots li button:before {
  font-size: 10px !important;
  color: #fff !important;
  opacity: 1 !important;
}

.slick-slider,
.slick-track,
.slick-list,
.slick-slider img,
.slick-slider div {
  outline: none;
}
